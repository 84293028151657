import * as React from "react";
import Container from "@mui/material/Container";
import Error from "../src/components/Error";
import defaultLanguage from "../src/translations/defaultLanguage";
import { Lang } from "../src/translations/Lang";
import EndOfCampaignText from "../src/components/EndOfCampaignText";

export default function Index() {
  // const [id, setId] = React.useState("");
  // const [loading, setLoading] = React.useState(false);
  // const [error, setError] = React.useState("");
  const [language, setLanguage] = React.useState(defaultLanguage);

  React.useEffect(() => {
    const lang = navigator.language;
    if (lang === "de-DE" || lang === "de-CH" || lang === "de") {
      setLanguage(Lang.de);
    }
    if (lang === "fr-FR" || lang === "fr" || lang === "fr-CH") {
      setLanguage(Lang.fr);
    }
    if (lang === "it-IT" || lang === "it" || lang === "it-CH") {
      setLanguage(Lang.it);
    }
    if (lang === "en-US" || lang === "en" || lang === "en-GB") {
      setLanguage(Lang.en);
    }
  }, []);

  return (
    <Container maxWidth='sm' sx={{ my: 4 }}>
      <EndOfCampaignText language={language} />
    </Container>
  );

  return (
    <Container maxWidth='sm'>
      <Error />
      {/* <Box sx={{ my: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant='h5' component='h1' gutterBottom>
              {getTranslation(Label.scanQRCodeText, language)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h5' component='h1' gutterBottom>
              {getTranslation(Label.enterYourID, language)}
            </Typography>
          </Grid>
          {error && (
            <Grid item xs={12}>
              <Alert severity='error'>
                <AlertTitle>
                  {getTranslation(Label.idNotFoundTitle, language)}
                </AlertTitle>
                {getTranslation(Label.idNotFoundText, language)}
              </Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              label="ID"
              required
              fullWidth
              value={id}
              onChange={(e) => setId(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Backdrop open={loading} sx={{ color: '#f3f3f3', opacity: 0.5 }}>
              <CircularProgress color="inherit" />
            </Backdrop>
            <Button fullWidth onClick={onSubmit} disabled={loading}>
              {getTranslation(Label.openMyDataButtonLabel, language)}
            </Button>
          </Grid>
          <Grid item xs={12} container justifyContent='center'>
            <FormControl>
              <InputLabel id='select-language-label' variant='filled'>
                {getTranslation(Label.language, language)}
              </InputLabel>
              <Select
                labelId='select-language-label'
                label={getTranslation(Label.language, language)}
                value={language}
                onChange={(e) => setLanguage(e.target.value as Lang)}
              >
                <MenuItem value={Lang.de}>
                  {getTranslation(Label.de, language)}
                </MenuItem>
                <MenuItem value={Lang.fr}>
                  {getTranslation(Label.fr, language)}
                </MenuItem>
                <MenuItem value={Lang.it}>
                  {getTranslation(Label.it, language)}
                </MenuItem>
                <MenuItem value={Lang.en}>
                  {getTranslation(Label.en, language)}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box> */}
    </Container>
  );
}
