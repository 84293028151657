import React from "react";
import { Lang } from "../../src/translations/Lang";
import { Typography, Link } from "@mui/material";

export default function EndOfCampaignText({ language }: { language: Lang }) {
  if (language === Lang.en) {
    return (
      <Typography sx={{ fontSize: "1.8rem", lineHeight: 1.4 }}>
        This page is no longer active. To complete your details, please contact
        us – either by calling our Patronage Centre on phone no.{" "}
        <Link href='tel:0844834844'>0844 834 844</Link> or by completing the
        online form at{" "}
        <Link href='https://www.rega.ch/contact'>www.rega.ch/contact</Link>.
        Thank you.
      </Typography>
    );
  }
  if (language === Lang.it) {
    return (
      <Typography sx={{ fontSize: "1.8rem", lineHeight: 1.4 }}>
        Questa pagina non è più attiva. Per aggiungere i vostri dati,
        contattateci telefonicamente, chiamando il Centro sostenitori al numero{" "}
        <Link href='tel:0844834844'>0844 834 844</Link>, oppure inviandoci il
        modulo online che trova su{" "}
        <Link href='https://www.rega.ch/contatto'>www.rega.ch/contatto</Link>.
        Grazie.
      </Typography>
    );
  }
  if (language === Lang.fr) {
    return (
      <Typography sx={{ fontSize: "1.8rem", lineHeight: 1.4 }}>
        Cette page n’est plus active. Pour compléter vos données, veuillez nous
        contacter, soit en appelant la centrale des donateurs au{" "}
        <Link href='tel:0844834844'>0844 834 844</Link>, soit en remplissant le
        formulaire en ligne disponible sur{" "}
        <Link href='https://www.rega.ch/contact'>www.rega.ch/contact</Link>.
        Merci.
      </Typography>
    );
  }
  return (
    <Typography sx={{ fontSize: "1.8rem", lineHeight: 1.4 }}>
      Diese Seite ist nicht mehr aktiv. Um Ihre Daten zu ergänzen, kontaktieren
      Sie uns bitte telefonisch via Gönner-Center unter der Nummer{" "}
      <Link href='tel:0844834844'>0844 834 844</Link> oder mittels
      Online-Formular unter{" "}
      <Link href='https://www.rega.ch/kontakt'>www.rega.ch/kontakt</Link>.
      Besten Dank.
    </Typography>
  );
}
